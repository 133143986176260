import * as React from 'react';
import UserContext from 'app/contexts/UserContextContainer/UserContext';
import RadioTabs from '../RadioTabs';
import { FormikProps } from 'formik';
import CreditCardForm from '../CreditCardForm/CreditCardForm';
import FormikFieldWrapper from '../FormikFieldWrapper/FormikFieldWrapper';

export type ExistingCardFormType = { useExistingCard: boolean; walletPaymentSourceId: number };
interface Props {
  formikProps: FormikProps<ExistingCardFormType>;
  creditCardFormPrefix: string;
  title?: string;
}

export const useDefaultWalletId = (): number => {
  const { currentUser } = React.useContext(UserContext);
  const defaultWallet = !!currentUser && currentUser.walletPaymentSources.find(wallet => wallet.default);
  const walletOptions =
    currentUser && currentUser.walletPaymentSources.length > 0
      ? currentUser.walletPaymentSources.filter(wallet => wallet.paymentSource !== null)
      : [];

  let walletPaymentSourceId: number;

  if (!!currentUser && walletOptions.length > 0) {
    if (defaultWallet) {
      walletPaymentSourceId = defaultWallet.id;
    } else {
      walletPaymentSourceId = walletOptions[0].id;
    }
  }
  return walletPaymentSourceId;
};

export const ExistingCreditCardForm = ({ formikProps, creditCardFormPrefix, title }: Props) => {
  const { currentUser } = React.useContext(UserContext);
  const walletOptions =
    currentUser && currentUser.walletPaymentSources.length > 0
      ? currentUser.walletPaymentSources
          .filter(wallet => wallet.paymentSource !== null)
          .map(wallet => ({
            label: `${wallet.paymentSource.name} - XXXX-XXXX-XXXX-${wallet.paymentSource.lastDigits} ${wallet.paymentSource.month}/${wallet.paymentSource.year}`,
            key: wallet.id,
          }))
      : [];
  return (
    <>
      {title && (
        <div data-cy="creditCardForm.title" className="md:mt-1">
          {title}
        </div>
      )}
      {walletOptions.length > 0 && (
        <RadioTabs name="useExistingCard" className="lg:mb-8">
          <FormikFieldWrapper
            name="useExistingCard"
            value
            labelComponent="Use Existing Card"
            componentType="radio"
            size="sm"
            dataCy="useExistingCard.true"
          />
          <FormikFieldWrapper
            name="useExistingCard"
            value={false}
            labelComponent="New Credit Card"
            componentType="radio"
            size="sm"
            dataCy="useExistingCard.false"
          />
        </RadioTabs>
      )}
      {formikProps.values.useExistingCard ? (
        <div className="mt-6 flex-1">
          <FormikFieldWrapper
            name="walletPaymentSourceId"
            options={walletOptions}
            componentType="combobox"
            labelComponent="Saved Credit Card"
            autoComplete="off"
            inPlaceError
          />
        </div>
      ) : (
        <CreditCardForm prefix={creditCardFormPrefix} />
      )}
    </>
  );
};
